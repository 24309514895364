const StartPage = () => import(/* webpackChunkName: "StartPage" */ './templates/startPage/StartPage');
const LandingPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/landingPage/LandingPage');
const TransportPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/transportPage/TransportPage');
const ArticlePage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/articlePage/ArticlePage');
const SearchPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/searchResultPage/SearchResultPage');
const EventPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/event/eventPage/EventPage');
const EventListPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/event/eventListPage/EventListPage');
const NewsListPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/archive/NewsListArchivePage');
const WaterRegionPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/waterRegionPage/WaterRegionPage');
const WizardPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/WizardPage');
const WizardStepPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/WizardStepPage');
const WizardListPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/WizardListPage');
const WizardPdfPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/WizardPdfPage');
const PlanningCyclePage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/planningCyclePage/PlanningCyclePage');
const ErrorPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/errorPage/ErrorPage');
const LoginPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/loginPage/LoginPage');
const ResetPasswordPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/resetPasswordPage/ResetPasswordPage');
const SubscriptionPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/subscriptionPage/SubscriptionTemplate');
const NewsSubscriptionPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/newsSubscriptionPage/NewsSubscriptionTemplate');
const WizardPrintPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/WizardPrintPage');
const VannportalenUnsubscribePage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/vannportalenUnsubscribePage/VannportalenUnsubscribePage');

// Blocks
const FileExplorerBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/fileExplorerBlock/FileExplorerBlock');
const ContactModuleBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/contactModule/ContactModule');
const HighlightedLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLink/HighlightedLink');
const HighlightedLinkList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLinkList/HighlightedLinkList');
const AccordionList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/accordionList/AccordionList');
const FactsBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/factsBlock/FactsBlock');
const CallToActionLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/callToActionLink/CallToActionLink');
const LinkListBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/linkListWithTextAndIcon/LinkListWithTextAndIcon');
const SectionBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/wizardPage/SectionBlock');
const XhtmlBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/vannportalenComponents/xhtmlBlock/XhtmlBlock');
const ExpandableXhtmlBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/wizards/ExpandableXhtmlBlock');
const ExtendedXhtmlBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/wizards/ExtendedXhtmlBlock');
const LawDataBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/lawDataAccordionV2/LawDataAccordion');
const DownloadButtonBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/downloadButton/DownloadButton');
const FileDownloadListBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/fileDownloadList/FileDownloadList');
const ContactCardBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/contactCardsBlock/ContactCardsBlock');
const ImageBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/imageBlock/ImageBlock');
const TableSelector = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/table/TableSelector');

export const EPI_COMPONENTS = [
    // Pages
    {
        id: 'VannportalenStartPage',
        componentPromise: StartPage
    },
    {
        id: 'VannportalenLandingPage',
        componentPromise: LandingPage
    },
    {
        id: 'VannportalenTransportPage',
        componentPromise: TransportPage
    },
    {
        id: 'VannportalenArticlePage',
        componentPromise: ArticlePage
    },
    {
        id: 'VannportalenSearchPage',
        componentPromise: SearchPage
    },
    {
        id: 'VannportalenEventPage',
        componentPromise: EventPage
    },
    {
        id: 'VannportalenEventListPage',
        componentPromise: EventListPage
    },
    {
        id: 'VannportalenNewsListPage',
        componentPromise: NewsListPage
    },
    {
        id: 'WaterRegionPage',
        componentPromise: WaterRegionPage
    },
    {
        id: 'VannportalenNewsArticlePage',
        componentPromise: ArticlePage
    },
    {
        id: 'VannportalenWizardPage',
        componentPromise: WizardPage
    },
    {
        id: 'VannportalenWizardStepPage',
        componentPromise: WizardStepPage
    },
    {
        id: 'VannportalenWizardListPage',
        componentPromise: WizardListPage
    },
    {
        id: 'VannportalenPdfWizardPage',
        componentPromise: WizardPdfPage
    },
    {
        id: 'PlanningCyclePage',
        componentPromise: PlanningCyclePage
    },
    {
        id: 'VannportalenLoginPage',
        componentPromise: LoginPage
    },
    {
        id: 'VannportalenResetPasswordPage',
        componentPromise: ResetPasswordPage
    },
    {
        id: 'VannportalenSubscriptionPage',
        componentPromise: SubscriptionPage
    },
    {
        id: 'NewsSubscriptionPage',
        componentPromise: NewsSubscriptionPage
    },
    // Blocks
    {
        id: 'FileExplorerBlock',
        componentPromise: FileExplorerBlock
    },
    {
        id: 'ContactForm',
        componentPromise: ContactModuleBlock
    },
    {
        id: 'HighlightedLinkBlock',
        componentPromise: HighlightedLink
    },
    {
        id: 'HighlightedLinkListBlock',
        componentPromise: HighlightedLinkList
    },
    {
        id: 'AccordionListBlock',
        componentPromise: AccordionList
    },
    {
        id: 'FactsBlock',
        componentPromise: FactsBlock
    },
    {
        id: 'CallToActionLinkBlock',
        componentPromise: CallToActionLink
    },
    {
        id: 'LinkListBlock',
        componentPromise: LinkListBlock
    },
    {
        id: 'VannportalenSectionBlock',
        componentPromise: SectionBlock
    },
    {
        id: 'XhtmlBlock',
        componentPromise: XhtmlBlock
    },
    {
        id: 'ExpandableXhtmlBlock',
        componentPromise: ExpandableXhtmlBlock
    },
    {
        id: 'ExtendedXhtmlBlock',
        componentPromise: ExtendedXhtmlBlock
    },
    {
        id: 'LawDataBlockV2',
        componentPromise: LawDataBlock
    },
    {
        id: 'DownloadButtonBlock',
        componentPromise: DownloadButtonBlock
    },
    {
        id: 'FileDownloadListBlock',
        componentPromise: FileDownloadListBlock
    },
    {
        id: 'ContactCardBlock',
        componentPromise: ContactCardBlock
    },
    {
        id: 'ImageBlock',
        componentPromise: ImageBlock
    },
    {
        id: 'VannportalenErrorPage',
        componentPromise: ErrorPage
    },
    {
        id: 'TableBlock',
        componentPromise: TableSelector
    },
    {
        id: 'VannportalenWizardPrintPage',
        componentPromise: WizardPrintPage
    },
    {
        id: 'VannportalenUnsubscribePage',
        componentPromise: VannportalenUnsubscribePage
    }
];

export default EPI_COMPONENTS;


