import { combineReducers } from 'redux';
import appReducer from './appReducer';
import sitesearchReducer from './sitesearchReducer';
// import feedbackBarReducer from './feedbackBarReducer';
// import glossaryReducer from './glossaryReducer';
// import mapReducer from './mapReducer';
// import environmentDataChartReducer from './environmentDataChartReducer';

export default combineReducers({
    app: appReducer,
    siteSearch: sitesearchReducer,
    // feedbackBar: feedbackBarReducer,
    // glossary: glossaryReducer,
    // map: mapReducer,
    // envDataChart: environmentDataChartReducer
});
